import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.awardOnChangeHandler = props.awardOnChangeHandler;
    this.awardMap = {"#mvp-mvp": 'MVP',
                      "#mvp-all": '',
                      "#mvp-hof": 'MVP Hall of Fame'};
  }

  

  componentDidMount() {
    window.addEventListener("hashchange", this.handleHashChange, false);
  }

  handleHashChange = (e) => {
    let hashValue = e.target.location.hash;
    if(hashValue in this.awardMap){
      this.awardOnChangeHandler(this.awardMap[hashValue]);
    }
    
  };


  render() {
    const {
      products,
      countries,
      regions,
      awardOnChangeHandler,
      productOnChangeHandler,
      countryOnChangeHandler,
      regionOnChangeHandler,
      clearFilterHandler,
      selectedRegion,
      selectedAward,
      selectedProduct,
      selectedCountry
    } = this.props;


    const standardizedRegions = new Map([
      ['amer', 'North America'],
      ['emea', 'Europe, Middle East & Africa'],
      ['apac', 'Asia-Pacific'],
      ['laca', 'Latin America']
    ]);

    const standardizedAwards = new Map([
      ['mvp', 'Salesforce MVP'],
      ['mvp hall of fame', 'Hall of Fame']
    ]);

    const noneOption = (<option value="" key="mvp-option-none">--None--</option>);
    let alumniBanner = (<div></div>);

    if (selectedAward == "Salesforce MVP Alum") {
      alumniBanner = (
        <tm-card-grid-a img-aspect="60%" class="slds-p-vertical_medium">
          <tm-grid-item 
            title="Salesforce MVP Alumni" 
            description="Salesforce MVP Alumni are Trailblazers who’ve served 5 years in the Salesforce MVP Program and are officially recognized as mentors to the next generation of Salesforce MVPs."
          />
        </tm-card-grid-a>
      )
    }
    
    return (
      <div>
        <div>
          <tm-page-navigation-a>
            <tm-page-navigation-item id="mvp-all" active="" href="#mvp-all" title="All"></tm-page-navigation-item>
            <tm-page-navigation-item id="mvp-mvp" href="#mvp-mvp" title="Salesforce MVP"></tm-page-navigation-item>
            <tm-page-navigation-item id="mvp-hof" href="#mvp-hof" title="Salesforce MVP Hall of Fame"></tm-page-navigation-item>
          </tm-page-navigation-a>
        </div>
        <div id="mvp-award-container">
        
        </div>
        <div className="slds-grid slds-wrap slds-gutters slds-p-bottom_medium">
                
         <div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-5">
            <div className="slds-form-element">
                <label className="slds-form-element__label C(white)" htmlFor="select-Product">Product</label>
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select className="slds-select C(blue-text)" id="select-Product" onChange={productOnChangeHandler} value={selectedProduct}>
                    {noneOption}
                    {                                                
                      products.map((oneProduct, i)=> {
                        return(<option value={oneProduct} key={`mvp-product-option-${i}`}>{oneProduct}</option>)
                      })
                    }
                    </select>
                  </div>
                </div>
            </div>
          </div>
  
          <div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-5">
            <div className="slds-form-element">
              <label className="slds-form-element__label C(white)" htmlFor="select-region">Region</label>
              <div className="slds-form-element__control">
                <div className="slds-select_container">
                  <select className="slds-select C(blue-text)" id="select-region" onChange={regionOnChangeHandler} value={selectedRegion}>
                    {noneOption}
                    {
                      regions.map((region, i)=> {
                        if(standardizedRegions.has(region.toLowerCase())) {
                          return(<option value={region} key={`mvp-region-option-${i}`}>{standardizedRegions.get(region.toLowerCase())}</option>  )
                        }
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
  
          <div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-5">
            <div className="slds-form-element">
                <label className="slds-form-element__label C(white)" htmlFor="select-country">Country</label>
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select className="slds-select C(blue-text)" id="select-country" onChange={countryOnChangeHandler} value={selectedCountry}>
                    {noneOption}
                    {
                      countries.map((oneCountry, index)=> {
                        return(
                          <option value={oneCountry} key={`mvp-country-option-${index}`}>{oneCountry}</option>  
                        )
                      })
                    }
                    </select>
                  </div>
                </div>
            </div>
          </div>

          <div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-5">
            <button className="slds-button slds-button_neutral Mt(1p5rem)" type="button" onClick={clearFilterHandler}>Clear Filters</button>
          </div>
        </div>
        <div>
           {alumniBanner}
        </div>
      </div>
    );
  }
}

Filters.propTypes = {
  products: PropTypes.array,
  countries: PropTypes.array,
  regions: PropTypes.array,
  awardOnChangeHandler: PropTypes.any,
  productOnChangeHandler: PropTypes.any,
  countryOnChangeHandler: PropTypes.any,
  regionOnChangeHandler: PropTypes.any,
  clearFilterHandler: PropTypes.any,
  selectedRegion: PropTypes.string,
  selectedProduct: PropTypes.string,
  selectedCountry: PropTypes.string
};

export default Filters;
